import * as React from 'react';
import { SmartLink } from 'components/smart-link/SmartLink';

import s from './Header.scss';

interface IHeaderProps {
  children?: React.ReactNode;
}

export const Header = ({ children }: IHeaderProps) => (
  <header className={s.header}>
    <div className={s.header__container}>
      <div className={s.header__content}>
        {/*<SmartLink
          to="/"
          label="jakobar.is"
        />
        <div className={s.header__navigation}>
          {children}
        </div>*/}
      </div>
    </div>
  </header>
);
