import * as React from 'react';
import Helmet from 'react-helmet';

import Logo from 'assets/svg/ueno-logo.svg';
import Dribbble from 'assets/svg/dribbble.svg';
import Twitter from 'assets/svg/twitter.svg';
import Github from 'assets/svg/github.svg';
import Instagram from 'assets/svg/instagram.svg';
import Facebook from 'assets/svg/facebook.svg';
import Linkedin from 'assets/svg/linkedin.svg';

import { helmet } from 'utils/helmet';
import { Header } from 'components/header/Header';
import { SmartLink } from 'components/smart-link/SmartLink';
import { Footer } from 'components/footer/Footer';
import { Devtools } from 'components/devtools/Devtools';

import s from './AppLayout.scss';

interface IAppLayoutProps {
  children: React.ReactNode;
}

const isDev = process.env.NODE_ENV === 'development';

export default ({ children }: IAppLayoutProps) => (
  <div className={s.layout}>
    <Helmet {...helmet} />

    <Header>
      {/*<SmartLink
        label="about"
        to="/about"
      />

      <SmartLink
        label="github"
        to="https://github.com/jakobar"
      >
        <Github />
      </SmartLink>*/}
    </Header>

    {children}

    <Footer

      social={[
        { icon: <Dribbble />, to: 'https://dribbble.com/jakobar' },
        { icon: <Twitter />, to: 'https://twitter.com/jakobarni' },
        { icon: <Github />, to: 'https://github.com/jakobar' },
        { icon: <Instagram />, to: 'https://www.instagram.com/jakobar_' },
        { icon: <Facebook />, to: 'https://www.facebook.com/jakobar' },
        { icon: <Linkedin />, to: 'https://www.linkedin.com/in/jakobar' },
      ]}
    />

    {isDev && <Devtools />}
  </div>
);
